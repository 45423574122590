export function fConvertDateFormat(data: string): string {
  if (data) {
    const date = new Date(data);
    const day = `0${date.getDate()}`.slice(-2);
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  return '';
}
